.video-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 100px auto 0 auto;
    padding: 20px;
    position: relative;
}


.video-placeholder {
    position: relative;
    cursor: pointer;
}

.thumbnail {
    width: 100%;
    height: 300px;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
    border-radius: 15px;
}

.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    /* background-color: rgba(0, 0, 0, 0.6); */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.play-icon:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.play-icon svg {
    width: 32px;
    height: 32px;
}
.video-container {
    width: 40%;
}

.video-container iframe {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.video-text-container{
    width: 50%;
}

.button-container {
    display: flex;
    gap: 10px;
}



.btn {
    text-decoration: none;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    background: #044FB0;

    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


@media (max-width: 768px) {
    .video-container {
        width: 100%;
        margin: 50px 0 30px 0;

    }
    
}
