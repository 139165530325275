.slider-container {
    margin-top: 50px;
    margin-bottom: 100px;
    width: 100%;
    overflow: hidden;
    padding: 20px 0;
}

.slider-container h2 {
    text-align: center;
}

.image_wrapper {
    margin:  40px 30px;
    position: relative;
    display: inline-block;
    text-align: center;
}

.image_wrapper img {
    width: 100%;
    max-width: 100px;
    object-fit: cover;
    border-radius: 50%;
    display: block;
    height: auto;
    transition: transform 0.3s ease;
}

.image_name {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(202, 197, 197, 0.8);
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.3s ease, top 0.3s ease;
    white-space: nowrap;
    z-index: 10; 
    pointer-events: none; 
}

.image_wrapper:hover .image_name {
    opacity: 1; 
    top: -34px; 
}