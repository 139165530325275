@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;600;700;800&display=swap');

/* .header-image-content {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  canvas {
    width: 100%;
    height: 100%;
  } */

.header {
    margin-top: 150px;
    margin-bottom: 84px;
}

.special-txt {
    display: inline-block;
  }
  
  .typewriter span {
    display: inline-block;
  }

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2em;
}

.header-text-content {
    width: 65%;
}

.header-text-content.english {
    text-align: left;
}

.header-text-content.arabic {
    text-align: right;
}

.header-text-content .special-txt {
    background: linear-gradient(90deg, #01C0BE 4.54%, #044FB0 80.35%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: capitalize;

}

.header-text-content.english .special-txt {
    text-align: left;
}

.header-text-content.arabic .special-txt {
    text-align: right;
}

.header-text-content h2 {
    /* color: #000; */
    color: var(--text-color);
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: capitalize;
    margin-bottom: 30px;
}

.header-txt {
    color: #868D94;
    font-size: 24px;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 30px;
    width: 75%;
}

.header-text-content.english .header-txt {
    text-align: left;
}

.header-text-content.arabic .header-txt {
    text-align: right;
}

.header-image-content {
    width: 38%;
    position: relative;
}

.header-image {
    width: 100%;
    border-radius: 8px;
}

.top-right-card {
    position: absolute;
    top: 0;
    right: 0;
    /* background: white; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top-right-card .card-amount {
    color: #206EE4;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 8px auto;
}

.top-right-card .card-text {
    color: #868D94;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 16px;
}

.top-right-card .user-images {
    display: flex;
    justify-content: center;
}

.top-right-card .user-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: -10px;
    border: 1px solid white;
}

.bottom-left-card {
    position: absolute;
    bottom: 1em;
    left: 0;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0.5em;
    border-radius: 8px;
    display: flex;
    gap: 1em;
}

.card-section {
    text-align: center;
}

.card-section .card-number {
    color: #206EE4;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
}

.card-section .card-text {
    margin-top: 0.5em;
    /* color: #000; */
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.card-border {
    height: 4px;
    width: 50%;
    margin: 0.5em auto;
}

.border1 {
    background-color: #42C75C;
}

.border2 {
    background-color: #FD8467;
}

.border3 {
    background-color: #5D4FE4;
}

.blue-text {
    color: #044FB0;
    font-weight: 500;
    margin-bottom: 0.5em;
}

.header-text-content .header-buttons {
    display: flex;
    gap: 1em;
    margin-top: 1em;
}

.header-text-content .header-buttons .btn {
    text-decoration: none;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    background: #044FB0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.header-text-content .header-buttons .btn2 {
    background: transparent;
    border: 1px solid #044FB0;
    color: #044FB0;
}

.header-text-content .header-buttons .btn2:hover {
    background: #044FB0;
    color: white;
}

.header-text-content .header-buttons .btn:hover {
    /* background-color: #206EE4; */
    background-color: #044FB0;
}

@media (max-width: 1060px) {
    .top-right-card {
        top: -20px;
        right: -2px;
    }
}
@media (max-width: 992px) {
    .header-image{
        display: none;
    }
    .top-right-card {
        width: 50%;
        top: -2em;
        right: -2em;
    }

    .card-section .card-number {
        font-size: 12px;
    }

    .top-right-card,
    .bottom-left-card {
        position: static;
        margin: 1em auto;
        width: 100%;
    }

    .top-right-card .card-text {
        font-size: 16px;
    }

    .top-right-card .user-image {
        width: 30px;
        height: 30px;
    }

    .bottom-left-card {
        margin: 1em auto;
        width: 100%;
        bottom: 0;
        left: 0;
    }

    .card-section .card-text {
        font-size: 14px;
    }

    .header-txt {
        font-size: 20px;
    }

    .header-text-content h2,
    .header-text-content .special-txt 
     {
        font-size: 28px;
    }

}


@media (max-width: 768px) {
    .header-content {
        flex-direction: column;
        align-items: flex-start;
    }
    .top-right-card,
    .bottom-left-card {
        position: static;
        margin: 1em auto;
        width: 48%;
    }
    .header {
        margin-top: 100px;
    }

    .header-content,
    .header-image-content {
        flex-direction: column;
        text-align: center;
    }

    .header-text-content,
    .header-image-content {
        width: 100%;
    }

    .user-images {
        justify-content: center;
    }

    .header-text-content h2 {
        font-size: 30px;
    }

    .special-txt {
        font-size: 30px;
    }

    .header-txt {
        font-size: 18px;
        width: 95%;
    }


    .header-image {
        width: 45%;
        text-align: center;
        margin: 0 auto;
        display: block;
    }
}

@media (max-width: 565px) {
    .top-right-card {
        width: 55%;
    }
    .bottom-left-card {
        width: 55%;
    }

}
@media (max-width: 510px) {
    .header-text-content .header-buttons {
        flex-direction: column;
        width: 100%;
    }

    .top-right-card,
    .bottom-left-card {
        position: static;
        margin: 1em auto;
    }
    .top-right-card {
        width: 65%;
    }
    .bottom-left-card {
        width: 65%;
    }

    .header-image {
        width: 60%;
    }
    .header-text-content h2,
    .header-text-content .special-txt 
     {
        font-size: 26px;
    }

}
@media (max-width: 410px) {
    .header-text-content .header-buttons {
        flex-direction: column;
        width: 100%;
    }

    .top-right-card,
    .bottom-left-card {
        position: static;
        margin: 1em auto;
        width: 90%;
    }

    .top-right-card {
        width: 70%;
    }
    .bottom-left-card {
        width: 70%;
    }
}
@media (max-width: 370px) {


    .top-right-card,
    .bottom-left-card  {
        width: 80%;
    }
}



