
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;600;700;800&display=swap');

html,body{
    /* -ms-overflow-style: none;  
    scrollbar-width: none;
    overflow: hidden; */
}

/* scrollbar */

::-webkit-scrollbar{
    width: 5px;
}


::-webkit-scrollbar-track{
    background: #d1e5ff;
}

::-webkit-scrollbar-thumb{
    background: linear-gradient(90deg, #01C0BE 4.54%, #044FB0 80.35%);
    border-radius: 10px;
}