.scroll-button {
    position: fixed;
    right: 30px;
    bottom: 110px;
    width: 50px;
    height: 50px;
    background-color: white;
    color: #044FB0;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    transition: opacity 0.4s, visibility 0.4s;
    z-index: 9999;
}

.scroll-button.hidden {
    opacity: 0;
    visibility: hidden;
}

.scroll-button.sidebar-open {
    display: none;
}

@media (max-width: 768px) {
    .scroll-button {
        width: 36px;
        height: 36px;
    }
}

@media (max-width: 350px) {
    .scroll-button {
        width: 35px;
        height: 35px;
        right: 20px;
        bottom: 75px;
    }
}
