@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;600;700;800&display=swap');


html,
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
}


/* Dark mode specific styles */
.navbar-dark .nav-link {
  color: #ffffff !important;
}

/* 6-10-2024 */
.navbar-light .nav-link {
  color: #ffffff !important;

  transition: all 0.3s ease;


}

.navbar-light .nav-link:hover {
  color: #bbbbbb !important;

  padding-left: 8px !important;
}

/* .navbar-light .nav-link.active {
    color: #ffffff !important;
  } */

.burger-icon-dark {
  border-color: #ffffff;
  color: #ffffff !important;
}

.light-icon-dark1 {
  color: yellow;
}

.dark-icon-dark1 {
  color: black;
}

/*  */



.offcanvas-dark {
  background-color: #000000;
}

.offcanvas-dark .nav-link {
  color: #ffffff !important;
}

.header-dark {
  background-color: #000000;
}

.logoImg {
  width: 80px;
  margin-top: 10px;
}


.light-icon-dark {
  color: #ffffff !important;
}

.dark-icon-dark {
  color: #ffffff !important;
}

@media screen and (max-width: 992px) {
  .offcanvas-header.arabic {
    flex-direction: row-reverse;
  }

  .offcanvas-header.english {
    flex-direction: row;
  }

  .offcanvas-header .btn-close {
    margin: 0;
  }

  .burger-icon-light {
    border-color: #000000;
  }
}


.close-light {
  background-color: #000000;
}


.offcanvas .close-dark {
  background-color: #ffffff;
}


/* --------------------------------------- */


.arabic {
  text-align: right;
  font-family: "Tajawal", sans-serif;
}

.english {
  text-align: left;
  font-family: "Poppins", sans-serif;
}



.navBar {
  /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20); */
  align-items: center;
  /* background: linear-gradient(90deg, #01C0BE 4.54%, #044FB0 80.35%); */

}

.bg-light-nav {
  /* background: linear-gradient(101deg, #1e40af -26.52%, #00ffc2 -26.5%, #1b51b1 63.42%); */
  background: linear-gradient(101deg, #01C0BE 0%, #044FB0 51.4%) !important;

}

.logoImg {
  width: 80px;
  margin-top: 10px;
}


.show-menu {
  right: 0;
}

.language-toggle-btn {
  border: none;
  /* color: #044FB0;  */
  color: white;
  font-size: 18px;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s ease;
  margin-top: 15px;
  align-items: center;
}

.language-toggle-btn:hover {
  /* background-color: #f0f0f0; */
  color: #bbbbbb;
}

.language-toggle-btn:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
}


.nav-link {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.274px;
}




/* Sidebar Responsive Design */
@media screen and (max-width: 992px) {
  .offcanvas {
    width: 50% !important;
    max-width: 100%;
  }


  .offcanvas-body {
    padding: 2rem 1rem;
  }

  .offcanvas .nav-link.arabic {
    text-align: right;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
    transition: color 0.3s ease-in-out;

  }

  .offcanvas .nav-link.english {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    transition: color 0.3s ease-in-out;
  }

  .offcanvas .nav-link:hover {
    color: #044FB0;
  }

  .offcanvas .language-toggle-btn {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    background-color: #044FB0;
    color: #fff;
    gap: 8px;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
  }

  .offcanvas .switcher-btn {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    gap: 8px;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    order: 1;
  }

  .switcher-btn .dark-icon {
    order: 4;
  }

  .switcher-btn .light-icon {
    order: -2;
  }

  .offcanvas .logoImg {
    width: 60px;
  }

}


.switcher-btn.english .dark-icon {
  order: 4;
}

.switcher-btn.english .light-icon {
  order: -2;
}

@media screen and (max-width: 768px) {
  .switcher-btn.english .dark-icon {
    order: 4;
  }

  .switcher-btn.english .light-icon {
    order: -2;
  }

  .switcher-btn.arabic .dark-icon {
    order: -2;
  }

  .switcher-btn.arabic .light-icon {
    order: 4;
  }

}

@media screen and (max-width: 576px) {
  .offcanvas {
    width: 60% !important;
  }
}


.offcanvas-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 992px) {
  .offcanvas-header.arabic {
    flex-direction: row-reverse;
  }

  .offcanvas-header.english {
    flex-direction: row;
  }

}


@media screen and (max-width: 330px) {
  .offcanvas .nav-link.english {
    text-align: left;
    font-size: 14px;
  }

  .offcanvas .logoImg {
    width: 60px;
  }

  .offcanvas .nav-link.arabic {
    font-size: 12px;

  }

  .offcanvas .nav-link.english {

    font-size: 12px;
  }
}




/* Close button color in dark mode */
.close-darkBtn {
  filter: invert(100%);
  /* Changes the icon to white */
}




.navbar-toggler-icon {
  background-image: url('data:image/svg+xml;charset=UTF8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30"><path stroke="white" stroke-width="2" d="M4 7h22M4 15h22M4 23h22" /></svg>') !important;
}

/* .navbar{
  --bs-navbar-toggler-icon-bg:  url('data:image/svg+xml;charset=UTF8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30"><path stroke="white" stroke-width="2" d="M4 7h22M4 15h22M4 23h22" /></svg>') !important;
} */


@media (max-width: 786px) {
  .switcher-btn {
    display: none;
  }
}