@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;600;700;800&display=swap');


.marginTopEvent {
    margin-top: 100px;
}

.specialTxt {
    background-image: linear-gradient(90deg, #01C0BE 4.54%, #044FB0 80.35%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 54px;
}

.dark-contact {
    color: white;
}

.arabic {
    text-align: right;
    font-family: "Tajawal", sans-serif;
}

.english {
    text-align: left;
    font-family: "Poppins", sans-serif;
}


.contact-main {
    /* margin: 20px auto; */
    width: 50%;
}

.contact-container {
    position: relative;
    /* max-width: 800px; */
    margin: auto;
    /* padding: 2em; */
    /* background-color: #fff; */
    border-radius: 8px;
    text-align: center;
}

.contact-title {
    /* color: #000; */
    color: var(--text-color);
    font-size: 54px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin-top: 170px;
}

.contact-paragraph {
    margin-bottom: 1.5em;
    color: #575757;
    font-size: 26px;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.form-row {
    display: flex;
    gap: 1em;
}

.form-input,
.form-textarea {
    width: 100%;
    padding: 0.75em;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
}



.form-input.half-width {
    width: calc(50% - 0.5em);
}

.form-textarea {
    height: 150px;
    resize: none;
}

.send-btn {
    padding: 0.75em;
    background-color: #044fb0;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;

    width: 100%;
    margin: 0 auto;
    transition: background-color 0.3s;
}

.send-btn:hover {
    background-color: #033c8a;
}

.form-input:focus,
.form-textarea:focus {
    border-color: #044fb0;
    outline: none;
}

@media screen and (max-width: 789px) {
    .form-row {
        flex-direction: column;
    }

    .contact-title {
        font-size: 28px;
    }

    .specialTxt {
        font-size: 30px;
        padding-top: 8px;
    }

    .contact-paragraph {
        font-size: 20px;
    }

    .form-input.half-width {
        width: 100%;
    }

    .marginTopEvent {
        margin-top: 100px;
    }

    .contact-title {
        margin-top: 100px;
    }

}


@media (max-width: 410px) {
    .contact-title {
        font-size: 24px;
    }

    .specialTxt {
        font-size: 26px;
        margin-top: 5px;
        line-height: 1.2;
        padding-top: 6px
    }

    .marginTopEvent {
        margin-top: 110px;
    }

}




.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-card {
    background-color: white;
    border-radius: 14px;
    padding: 30px;
    text-align: center;
    width: 90%;
    max-width: 450px;
}

.popup-icon {
    width: 150px;
    margin: 0 auto;
}

.popup-message {
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
    text-align: center;
}

.popup-submessage {
    margin-top: 10px;
    font-size: 16px;
    text-align: center;
}

.popup-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #044fb0;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
}

.popup-button:hover {
    background-color: #044fb0;
}



.form-map{
    display: flex;
    justify-content: space-between;
    margin: 20px auto 120px auto;
    width: 100%;    
}

.map-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 80px;
    width: 45%;
}

.map-container {
    width: 100%;
    margin: 0 auto ;
    height: 0;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.map-container iframe {
    height: 342px;
    /* margin-top: 30px; */
    border-radius: 15px;
}


@media screen and (max-width: 789px) {
    .contact-main {
        width: 100%;
        order: 0;
    }

    .form-map {
        flex-direction: column;
        gap: 60px;
        margin-bottom: 300px;
    }  
    
    .map-info { 
        width: 100%;  
        order: 1; 
     
    }
}


/*  */

.illustration{
    position: relative;
    width: 100%;
    /* height: 100vh; */
    /* background: url(../styles/illustration.jpg) no-repeat top center; */
    background-size: cover;
    background-position: center;
    margin: 0 auto;
}
/* h1{
    position: absolute;
    text-align: center;
    width: 100%;
    color: #01fefd;
    padding-top: 220px;
    font-size: 90px;
} */
.ilarge, .imeduim, .ismall{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.ilarge{
    /* background: url(../styles/particules_large.png) repeat 0 0; */
    animation: drop 2s linear infinite;
}
.imeduim{
    /* background: url(../styles/particules_medium.png) repeat 0 0; */
    animation: drop 12s linear infinite;
}.ilarge{
    /* background: url(../styles/particules_large.png) repeat 0 0; */
    animation: drop 27s linear infinite;
}

@keyframes drop {
    from{
        background-position: 0 0;
    }

    to{
        background-position: 0 413px;
    }
}