@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;600;700;800&display=swap');

.english-feature .feature-icon {
    margin-right: 8px;
}

.arabic-feature .feature-icon {
    margin-left: 8px;
}


.arabic {
    text-align: right;
    font-family: "Tajawal", sans-serif;
}

.english {
    text-align: left;
    font-family: "Poppins", sans-serif;
}


.checkout-page {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin-top: 100px;
    border-radius: 22px;
    /* background: #F6F6F6; */
}

.payment-methods {
    margin-right: 20px;
}

.selected-package {
    flex-direction: column;
    padding: 15px;
    width: 40%;

    border-radius: 22px;
    /* background: #FFF; */
    /* background: var(--background-card); */
    box-shadow: 0px 5.074px 18.268px 0px rgba(0, 0, 0, 0.06);
}

.selected-package .card-details {
    display: flex;
}

.selected-package img {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
}

.payment-methods ul {
    list-style: none;
    padding: 0;
}

.payment-methods ul li {
    margin-bottom: 10px;
}

.selected-package .bottom-div {
    border-top: 1px solid #F6F6F6;
    padding: 15px 0;
}


.selected-package .bottom-div .total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 40px;

}

.selected-package .bottom-div .total p {
    /* color: #000; */
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.selected-package .bottom-div .subscription-btn {
    width: 100%;
    border-radius: 14px;
    border: none;
    background: #044FB0;
    color: #F6F6F6;
    padding: 15px 0;

}

.selected-package .bottom-div .terms {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 15px;

}

.selected-package .bottom-div .terms-link {
    color: #000;

}


.card-title-checkout {
    /* color: #1B1E28; */
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0.5rem 0;

}

/* 100 منتج شهريا */

.card-number-checkout {
    color: #575757;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin: 0.5rem 0;
    line-height: normal;
}




.payment-methods {
    width: 50%;
    margin-right: 20px;
}

.payment-method-labels {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
}

.payment-label {
    /* background: #FFF; */
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
}


.payment-method-labels {
    display: flex;
    flex-direction: column;
}

.payment-label {
    display: flex;
    align-items: center;
    background: #F6F6F6;
    padding: 5px 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    margin-bottom: 15px;
}

.payment-label span {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 23.274px;
}

.payment-label input[type="radio"] {
    margin-right: 10px;
}

.payment-image {
    margin: 0 10px;
    width: 90px;
    height: 70px;
    background-color: #F5F5F5;
    border-radius: 15px;
    padding: 15px;
    margin-right: 30px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    object-fit: contain;
}



.styled-input {
    width: 20px;
    height: 20px;
    border: 2px solid #000;
    padding: 5px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    outline: none;
    /* appearance: none; */
    border-radius: 50%;
}

.styled-input:checked {
    border-color: #007bff;
}



@media (max-width: 768px) {
    .checkout-page {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        width: 95%;
        margin: 0 auto;
    }

    .selected-package {
        width: 100%;
        margin-bottom: 20px;
    }

    .payment-methods {
        width: 100%;
    }

    .payment-method-labels {
        margin-top: 40px;
    }

    .bottom-div {
        padding: 20px 10px;
    }

    .selected-package .bottom-div .total {
        flex-direction: column;
        padding: 10px 20px;
        text-align: center;
    }

    .selected-package .bottom-div .total p {
        font-size: 20px;
    }

    .selected-package .bottom-div .subscription-btn {
        padding: 12px;
    }
}